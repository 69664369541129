import React from "react";
import { Link } from "gatsby"; 
import image from './img/NPN-2units.png'

const getLastDayOfCurrentMonth = () => {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0); // The 0th day of next month is the last day of the current month.
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return lastDay.toLocaleDateString(undefined, options); // Format it as "Month Day, Year"
}

const TanklessWaterHeaterPromotion = () => (
  <div
    className={`rounded-lg mb-2 shadow bg-white  `}
    style={{
      background: "linear-gradient(145deg, rgb(215 42 43) 50%, rgb(26 26 26) 50%)",
    }}
  >
    <div className="px-8 pt-6 relative z-1">
      <h4 className="text-white font-bold text-3xl md:text-4xl">
        $30 OFF
      </h4>
      <h3 className="text-white font-bold text-lg md:text-xl">
        On any purchase of $300 or more
      </h3>
      <Link
        to={'/contact'}
        className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
      >
        Claim Offer
      </Link>
    </div>

    <div className="px-8 py-3">
      <img 
        src={image}
        className="my-[-4rem] z-0"
        alt="Window Tinting Sacramento"
      />  
      <p className="text-sm text-center text-gray-500 italic">
        *Offer expires {getLastDayOfCurrentMonth()}
      </p>
    </div>
  </div>
);

export default TanklessWaterHeaterPromotion;
